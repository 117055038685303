<script>
	import Suggestions from "./Suggestions.svelte";
	import FormFieldInput from "./FormFieldInput.svelte";
	import FormFieldLabel from "./FormFieldLabel.svelte";

	export let title = undefined;
	export let subtitle = "";
	export let fieldTitle;
	export let fieldClassName = "";
	export let store;
	export let isError = false;
	export let variant = "default";

	let isAddressFieldFocused = false;

	function didSelectSuggestion(e) {
		const { address, coordinates } = e.detail.suggestion;

		const [_, street, city, state, zip] = address.match(
			/^(.*), (.*), (.*) (\d+)$/
		);

		store.updateData("street", street);
		store.updateData("zip", zip);
		store.updateData("city", city);
		store.updateData("state", state);
		store.updateData("latitude", coordinates[1]);
		store.updateData("longitude", coordinates[0]);
	}
</script>

<div class={`modal-grid modal-grid-half-gap form-field-address-${variant}`}>
	{#if title !== undefined}
		<div class="modal-grid-column-6 contact-page-header">
			<FormFieldLabel {variant}>
				<span slot="label">{title}</span>
				<span slot="subtitle">{subtitle}</span>
			</FormFieldLabel>
		</div>
	{/if}
	<div class="modal-grid-column-6">
		<div class="spacer-16 m-spacer-8" />
		<FormFieldInput
			{variant}
			title={fieldTitle}
			name="street"
			{store}
			className={fieldClassName}
			{isError}
			bind:isFocused={isAddressFieldFocused} />

		<Suggestions
			variant="contact"
			query={$store.data.street}
			isVisible={isAddressFieldFocused}
			on:select={didSelectSuggestion} />
	</div>
	<div class="modal-grid-column-3">
		<div class="spacer-12 m-spacer-8" />
		<FormFieldInput
			{variant}
			title="ZIP code"
			name="zip"
			{store}
			className={fieldClassName} />
	</div>
	<div class="modal-grid-column-3">
		<div class="spacer-12 m-spacer-8" />
		<FormFieldInput
			{variant}
			title="City"
			name="city"
			{store}
			className={fieldClassName} />
	</div>
</div>
