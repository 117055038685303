<div class="modal-grid">
    <div class="modal-grid-column-6 contact-page-header">
        <h1 class="type-24 m-type-21">
            <slot name="title" />
        </h1>
        <div class="spacer-16" />
        <p class="type-18 m-type-15 color-text-60">
            <slot name="subtitle" />
        </p>
    </div>
</div>

<style>
    .contact-page-header {
        text-align: center;
    }

    .contact-page-header p {
        margin: 0 auto;
        width: 80%;
    }

    @media (max-width: 768px) {
        .contact-page-header p {
            width: 100%;
        }
    }
</style>
