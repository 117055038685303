<script>
	import contact from "../../../stores/contact.js";
	import Header from "../../Header.svelte";
	import { getContext } from "svelte";
	import { key } from "../../../../common/modal.js";

	getContext(key)?.setBackgroundClickable(true);

	const { zip } = $contact.data;

	contact.resetData();
</script>

<div class="success">
	<div class="confirmation-icon">
		<img src="/assets/images/icons/success.svg" alt="Checkmark" width="44" height="44" />
		<div class="spacer-24" />
	</div>

	<Header>
		<span slot="title">You’re on the list</span>
		<span slot="subtitle">
			We’ll let you know when Backyard<br />
			is available in&nbsp;{zip || "your area"}.
		</span>
	</Header>
</div>

<style>
	.confirmation-icon {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	@media (max-width: 768px) {
		img {
			width: 36px;
			height: 36px;
		}
	}
</style>
