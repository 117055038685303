<script>
	import Calendar from "../../Calendar.svelte";
	import Times from "../../Times.svelte";
	import Header from "../../Header.svelte";
	import contact, { HELLO_STEPS } from "../../../stores/contact.js";
	import slots from "../../../stores/slots.js";
	import Loader from "../../../../common/Loader.svelte";
	import Features from "../../Features.svelte";
	import Error from "../../Error.svelte";
	import createErrorLink from "../../../utils/createErrorLink";
	import { DateTime } from "luxon";

	const quickSlots = slots("hello");

	let isLoading = false;
	let isFailed = false;
	let selectedDate = $contact.data.slot;
	let selectedSlot = $contact.data.slot;

	$: selectedSlot && contact.updateData("slot", selectedSlot);

	$: availableSlotsForSelectedDate =
		selectedDate &&
		$quickSlots.slots &&
		$quickSlots.slots.filter(
			(slot) => slot.month === selectedDate.month && slot.day === selectedDate.day
		);

	$: errorLink = createErrorLink("Site error: requesting a quick estimate review", [
		["Request type", "Quick estimate review"],
		[
			"Requested time",
			$contact.data.slot && $contact.data.slot.toLocaleString(DateTime.DATETIME_MED)
		],
		["Name", `${$contact.data.firstName} ${$contact.data.lastName}`],
		["Installation address", $contact.data.address],
		["Phone number", $contact.data.phone]
	]);

	async function didClickContinueButton() {
		isLoading = true;
		isFailed = false;

		try {
			const res = await API.bookAppointment(
				"hello",
				$contact.data.slot.toISO(),
				$contact.data
			);

			if (res.ok !== true) {
				throw new Error("failed to book appointment");
			}

			contact.openHelloStep(HELLO_STEPS.scheduleSuccess);
		} catch (error) {
			isFailed = true;
			console.error(error);
		}

		isLoading = false;
	}
</script>

<div class="contact-schedule-picker" class:contact-schedule-picker-loading={isLoading}>
	<Header>
		<span slot="title">Schedule a follow-up call</span>
		<span slot="subtitle">
			To get your estimate, schedule a quick
			<nobr class="nobr">15-minute call</nobr> with a specialist.
		</span>
	</Header>

	<div class="spacer-50 m-spacer-32" />
	<Features />

	<div class="spacer-50" />

	{#if $quickSlots.isLoading}
		<div class="slots-loading-message type-15 color-text-60">
			Loading available dates <Loader />
		</div>
	{:else}
		<Header>
			<span slot="title">When should we call you?</span>
			<span slot="subtitle">Times are displayed in Pacific Time.</span>
		</Header>
		<div class="spacer-50 m-spacer-32" />
		<hr class="divider" />
		<div class="spacer-32" />
		<Calendar availableDates={$quickSlots.dates} bind:selectedDate />
	{/if}

	{#if selectedDate !== undefined}
		<div class="spacer-64 m-spacer-32" />
		<hr class="divider" />
		<div class="spacer-50" />
		<Times
			variant="confirmable"
			{selectedDate}
			availableSlots={availableSlotsForSelectedDate}
			bind:selectedSlot
			on:confirm={didClickContinueButton}
			{isLoading}>
			{#if isFailed}
				<Error>
					<a href={errorLink}>Click here</a> to schedule your call via email.
				</Error>
				<div class="spacer-8"></div>
			{/if}
		</Times>
	{/if}
</div>

<style>
	.slots-loading-message {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}

	.contact-schedule-picker-loading {
		pointer-events: none;
	}
</style>
