<script>
	import iconSuccess from "../../../images/icons/success.svg";

	export let title;
	export let body;
</script>

<div class="success">
	<div class="spacer-100 m-spacer-64" />
	{@html iconSuccess}
	<div class="spacer-32" />
	<h1 class="type-24">
		{title}
	</h1>
	<div class="spacer-8" />
	<p class="type-18 color-text-40">
		{body}
	</p>
	<div class="spacer-100 m-spacer-64" />
</div>

<style>
	.success {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	p {
		width: 80%;
		text-align: center;
	}
</style>
