<script>
	import contact from "../../../stores/contact.js";
	import Header from "../../Header.svelte";
	import CalendarLinks from "../../../../common/CalendarLinks.svelte";
	import Features from "../../Features.svelte";
	import { getContext } from "svelte";
	import { key } from "../../../../common/modal.js";
	import { simpleFormattedLuxonTime } from "../../../../common/time.js";
	import iconCalendar from "../../../../../images/icons/calendar.svg";

	getContext(key)?.setBackgroundClickable(true);

	$: formattedDate =
		$contact.data.slot &&
		$contact.data.slot.toLocaleString({
			weekday: "long",
			month: "long",
			day: "numeric",
			year: "numeric"
		});

	$: calendarDescription =
		"During this 15 minute phone call a Samara Specialist will answer your questions and help you choose a Backyard configuration that best suits your needs.";

	gtag("event", "hello_review_scheduled");
</script>

<div class="success">
	<div class="confirmation-icon">
		<img src="/assets/images/icons/success.svg" alt="Checkmark" width="44" height="44" />
		<div class="spacer-24" />
	</div>

	<Header>
		<span slot="title">You’re all set</span>
		<span slot="subtitle">
			We’ll send you an email with everything you need to know about your&nbsp;call.
		</span>
	</Header>

	{#if $contact.data.slot}
		<div class="spacer-50 m-spacer-32" />
		<Features
			leftText={simpleFormattedLuxonTime($contact.data.slot)}
			rightIcon={iconCalendar}
			rightText={formattedDate} />

		<div class="spacer-50 m-spacer-32" />
		<div class="confirmation-download">
			<CalendarLinks
				variant="centered"
				date={$contact.data.slot}
				title="Quick phone call with Samara"
				description={calendarDescription}
				address="Phone call"
				duration={15} />
		</div>
	{/if}
</div>

<style>
	.confirmation-icon {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	@media (max-width: 768px) {
		img {
			width: 36px;
			height: 36px;
		}
	}
</style>
