<script>
	import contact, { HELLO_STEPS } from "../../../stores/contact.js";
	import slots from "../../../stores/slots.js";
	import Footer from "../../Footer.svelte";
	import iconSuccess from "../../../../../images/icons/success.svg";
	import isServicableAddress from "../../../../common/isServicableAddress";

	const isServicable = isServicableAddress($contact);

	function didClickContinueButton() {
		contact.openHelloStep(HELLO_STEPS.schedule);

		gtag("event", "hello_continue_button");
	}

	if (isServicable) {
		slots("hello").refresh($contact.data.zip);
	}
</script>

<div class="success hello-success">
	<div class="hello-success-icon">{@html iconSuccess}</div>
	<div class="spacer-32 m-spacer-16" />
	<h1 class="type-24 m-type-21">We got your info</h1>
	<div class="spacer-8" />
	<p class="type-18 m-type-15 color-text-60">
		We’ll use the information you shared to prepare your&nbsp;estimate.
	</p>
	{#if isServicable}
		<div class="spacer-32" />
		<hr class="divider" />
		<div class="spacer-32" />
		<h2 class="type-24 m-type-21 color-text-40">Next step:</h2>
		<h3 class="type-24 m-type-21">Schedule a follow-up call</h3>
		<div class="spacer-8" />
		<p class="type-18 m-type-15 color-text-60">
			To get your estimate, schedule a quick
			<nobr class="nobr">15-minute call</nobr> with a&nbsp;specialist.
		</p>
		<div class="hello-success-footer">
			<Footer submitTitle="Continue" canSubmit={true} on:submit={didClickContinueButton} />
		</div>
	{/if}
</div>

<style>
	.success {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	p {
		width: 80%;
		text-align: center;
	}

	.hello-success-footer {
		width: 100%;
	}

	@media (max-width: 768px) {
		.hello-success-icon {
			transform: scale(0.8);
		}
	}
</style>
