<script>
	import iconClock from "../../../images/icons/clock.svg";
	import iconPhone from "../../../images/icons/phone-circled.svg";

	export let leftIcon = iconClock;
	export let leftText = "15 minutes";
	export let rightIcon = iconPhone;
	export let rightText = "Phone call";
</script>

<div class="contact-schedule-feature-group">
	<div class="type-18 m-type-15 contact-schedule-feature">
		{@html leftIcon}
		{leftText}
	</div>
	<div class="type-18 m-type-15 contact-schedule-feature">
		{@html rightIcon}
		{rightText}
	</div>
</div>

<style>
	.contact-schedule-feature-group {
		display: flex;
		gap: 16px;
		border-top: var(--color-black-10) 1px solid;
		border-bottom: var(--color-black-10) 1px solid;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.contact-schedule-feature {
		display: flex;
		align-items: center;
		gap: 8px;
		width: 100%;
		max-width: 50%;
		white-space: nowrap;
	}

	.contact-schedule-feature:last-child {
		flex-grow: unset;
		max-width: unset;
	}
</style>
