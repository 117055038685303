<script>
	import contact, {
		CONTACT_PAGES,
		SCHEDULE_STEPS,
		MESSAGE_STEPS
	} from "../stores/contact.js";

	import Option from "./Option.svelte";

	import iconCalendar from "../../../images/icons/calendar-blue.svg";
	import iconMail from "../../../images/icons/mail-blue.svg";
	import iconPhone from "../../../images/icons/phone-blue.svg";

	function didClickSendMessageButton(e) {
		e.preventDefault();
		contact.openPage(CONTACT_PAGES.message);
		contact.openMessageStep(MESSAGE_STEPS.compose);
	}

	function didClickSpeakWithExpertButton(e) {
		e.preventDefault();
		contact.openPage(CONTACT_PAGES.schedule);
		contact.openScheduleStep(SCHEDULE_STEPS.intro);
	}

	function didClickCallUsButton(e) {
		e.preventDefault();
		location.href = "tel:650-420-2607";
	}
</script>

<div class="contact-options modal-grid-column-6">
	<Option on:click={didClickSpeakWithExpertButton}>
		<div slot="icon">
			{@html iconCalendar}
		</div>
		<div slot="title">Schedule a phone call</div>
		<div slot="subtitle">
			Book a 15 minute phone call to learn how Backyard works on your
			property.
		</div>
	</Option>
	<Option on:click={didClickSendMessageButton}>
		<div slot="icon">
			{@html iconMail}
		</div>
		<div slot="title">Send us a message</div>
		<div slot="subtitle">
			Prefer email? A Samara specialist will get back to you within one
			business day.
		</div>
	</Option>
	<Option on:click={didClickCallUsButton}>
		<div slot="icon">
			{@html iconPhone}
		</div>
		<div slot="title">
			<a href="tel:650-420-2607">Give us a call</a>
		</div>
		<div slot="subtitle">
			Call us at <a href="tel:650-420-2607">(650) 420-2607</a>, available
			9&nbsp;a.m.&nbsp;–&nbsp;5&nbsp;p.m.&nbsp;PT, Monday through Friday.
		</div>
	</Option>
</div>

<style>
	.contact-options {
		display: inline-flex;
		flex-direction: column;
		gap: 16px;
	}
</style>
